import { setupWebKeplr } from "cosmwasm";
import { stringToPath } from "@cosmjs/crypto"
import { coins } from "@cosmjs/launchpad"

const isPro = true //process.env.NODE_ENV === 'production'

let web3 = null
let contractPermaLock = null
let contractERC20 = null

// TODO => .env
const contractPermaLockAddress = '0x8Ec769e84790Be3666D263313903D12Cf0bc1972'
const chainId = 'odin-mainnet-freya'
const contractAddr =
  "odin17p9rzwnnfxcjp32un9ug7yhhzgtkhvl9jfksztgw5uh69wac2pgsccw0y2";

const config = {
  chainId: chainId,
  rpcEndpoint: "https://node.odin-freya-website.odinprotocol.io/mainnet/a/",
  prefix: "odin",
}

const fee = {
  amount: [
    {
      denom: "loki",
      amount: "2000",
    },
  ],
  gas: "150000",
}


let odinAddress = ''
let ethAddress = ''
let client = null

const chainInterface = {
  chainId: 'odin-mainnet-freya',
  chainName: 'ODIN118',
  rpc: 'http://35.241.221.154:26657',
  rest: 'http://35.241.221.154:1317',
  bip44: {
    coinType: 118,
  },
  bech32Config: {
    bech32PrefixAccAddr: 'odin',
    bech32PrefixAccPub: 'odin' + 'pub',
    bech32PrefixValAddr: 'odin' + 'valoper',
    bech32PrefixValPub: 'odin' + 'valoperpub',
    bech32PrefixConsAddr: 'odin' + 'valcons',
    bech32PrefixConsPub: 'odin' + 'valconspub',
  },
  currencies: [
    {
      coinDenom: 'odin',
      coinMinimalDenom: 'loki',
      coinDecimals: 6,
      coinGeckoId: 'odin',
    },
  ],
  feeCurrencies: [
    {
      coinDenom: 'odin',
      coinMinimalDenom: 'loki',
      coinDecimals: 6,
      coinGeckoId: 'odin',
    },
  ],
  stakeCurrency: {
    coinDenom: 'odin',
    coinMinimalDenom: 'loki',
    coinDecimals: 6,
    coinGeckoId: 'odin',
  },
  coinType: 118,
  gasPriceStep: {
    low: 0.01,
    average: 0.025,
    high: 0.03,
  },
}

let balance = 0
let ticketPrice = 0
let priceToPay = 0
let isWhiteListed = false
let contractERC20Address = null

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

document.addEventListener('DOMContentLoaded', async function () {
  document.querySelector('#o9w_to_lock').value = 0
  let init = setInterval(async function () {
    try {
      if (window.ethereum) {
        clearInterval(init)
        web3 = new Web3(window.ethereum)
        window.ethereum.on('accountsChanged', (accounts) => {
          console.log('Accounts changed')
          window.location.reload()
        })

        window.ethereum.on('chainChanged', (chainId) => {
          console.log('Network changed')
          window.location.reload()
        })
        await window.ethereum.enable()
        ethAddress = web3.currentProvider.selectedAddress
        if (window.keplr) {
          try {
            await window.keplr.enable(chainId)
          } catch (e) {
            try {
              await window.keplr.experimentalSuggestChain(chainInterface)
            } catch (e) {
              document.querySelector('.geo-db__loader-title').innerHTML =
                'Initialization failed! ❌'
            }
          }
          await window.keplr.enable(chainId)
          const offlineSigner = window.getOfflineSigner(chainId)
          const accounts = await offlineSigner.getAccounts()
          odinAddress = accounts[0]['address']
          client = await setupWebKeplr(config);
          const contractConfig = await client.queryContractSmart(contractAddr, { "config": {} })
          const userInfo = await client.queryContractSmart(contractAddr, { "locker_eth_info": { "addr": ethAddress } })
          const userBalance = await client.getBalance(odinAddress, "mO9W")
          ticketPrice = parseInt(contractConfig.coin_to_lock.amount)

          balance = parseInt(userBalance.amount)
          document.querySelector('#o9w_tickets').value = userInfo
          document.querySelector('#lock_address').value = ethAddress
          document.querySelector('#o9w_to_lock').value = (ticketPrice / 1000000)
        } else {
          throw new Error('keplr')
        }
        document.querySelector('.geo-db__loader').classList.remove('show')
      } else if (window.web3) {
        clearInterval(init)
        web3 = new Web3(window.web3.currentProvider)
        document.querySelector('.geo-db__loader').classList.remove('show')
      } else {
        // Please install metamask https://metamask.io/download
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌ </br></br> Please install <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">MetaMask</a>'
      }
    } catch (error) {
      console.error(error)
      if (error.message === 'keplr') {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌ </br></br> Please install <a href="https://www.keplr.app/" target="_blank" rel="noopener noreferrer">Keplr</a> and create an account!'
      } else if (error.message.includes('no chain info for')) {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌ </br></br> Please approve the Chain add resquest from Keplr!'
      } else if (error.message.includes('Request rejected')) {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌ </br></br> Please approve the connection of the Keplr account to the Chain!'
      } else if (error.message.includes('wrongEthNetwork')) {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌ </br></br> Please change metamask network to Binance Mainnet!'
      } else {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌'
      }
      document.querySelector('.geo-db__loader-title').innerHTML +=
        '</br></br> Please refresh the page.'
    }
  }, 1000)
})

document
  .querySelector('.geo-db__form')
  .addEventListener('submit', async function (e) {
    e.preventDefault()

    document.querySelector('.geo-db__form-submit-fancy').disabled = true
    document.querySelector('.geo-db__loader').classList.add('show')
    document.querySelector('.geo-db__loader-title').innerHTML =
      'Sending Transaction! :)'

    if (document.querySelector('.geo-db__form-info.error')) {
      document
        .querySelector('.geo-db__form-info.error')
        .classList.remove('error')
    }

    if (
      document.querySelector('.geo-db__form-info').style.display === 'block'
    ) {
      document.querySelector('.geo-db__form-info').innerHTML = ''
      document.querySelector('.geo-db__form-info').style.display = 'none'
    }

    try {
      if (ticketPrice > balance) {
        document.querySelector(
          '.geo-db__form-info'
        ).innerHTML = `Insufficient funds!`
        document.querySelector('.geo-db__form-info').classList.add('error')
        throw new Error('Insufficient funds')
      }

      const tx = await client.execute(odinAddress, contractAddr, { "lock_coins": { "eth_address": ethAddress } }, fee, "", coins(ticketPrice, "mO9W"))

      const txLink = `https://ping.pub/odin/tx/${tx.transactionHash}`
      document.querySelector('.geo-db__loader-title')
        .innerHTML = `Transaction was sent, your TxHash: ${tx.transactionHash},<br/> Please wait till tx finish!`
      document.querySelector('.geo-db__form-info')
        .innerHTML = `Transaction success, check your TxHash: <br/> <a href="${txLink}" target="_blank" rel="noopener noreferrer">${txLink}</a>`
      document.querySelector('#o9w_tickets').value = parseInt(document.querySelector('#o9w_tickets').value) + 1
    } catch (error) {
      console.error(error)
      if (error.message === "Request rejected") {
        document.querySelector('.geo-db__form-info').innerHTML =
          '<h2 class="error">User denied transaction signature</h2>'
      } else {
        document.querySelector('.geo-db__form-info').innerHTML =
          '<h2 class="error">Something has gone wrong</h2>'
      }
      document.querySelector('.geo-db__form-info').classList.add('error')
    }
    document.querySelector('.geo-db__form-submit-fancy').disabled = false
    document.querySelector('.geo-db__loader').classList.remove('show')
    if (document.querySelector('.geo-db__form-info').childNodes.length > 0) {
      document.querySelector('.geo-db__form-info').style.display = 'block'
    }
    document.querySelector('.geo-db__loader').classList.remove('show')
  })